import styled, { themeGet, device } from "@styled";

export const IntegrationsWrapper = styled.section`
  padding: 90px 0;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.primaryDark")},
    ${themeGet("colors.primary")}
  );

  @media ${device.medium} {
    ppadding: 60px 0;
  }
  @media ${device.small} {
    padding: 40px 0;
  }
  .section-title {
    h6 {
      color: #fff;
    }
    h2 {
      border-color: #fff;
    }
    p {
      color: #fff;
    }
  }
`;
export const IntegrationsInner = styled.div``;
export const IntegrationsIconbox = styled.div`
  align-items: center;
`;
export const IntegrationsContent = styled.div``;
export const ButtonWrap = styled.div`
  margin-top: 30px;
`;
export const IntegrationsIconwrap = styled.div`
  background: #fff;
  border-radius: 50%;
  width: 94px;
  height: 94px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px 5px rgb(79 21 127 / 20%);
  &:nth-child(2) {
    width: 118px;
    height: 118px;
  }
  &:nth-child(4) {
    width: 130px;
    height: 130px;
  }
  &:nth-child(5) {
    width: 118px;
    height: 118px;
  }
  &:nth-child(6) {
    width: 100px;
    height: 100px;
  }
  &:nth-child(7) {
    width: 84px;
    height: 84px;
  }
  &:nth-child(8) {
    width: 154px;
    height: 154px;
  }
`;
