import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Button from "@ui/button";
import Image from "@ui/image";
import {
  IntegrationsWrapper,
  IntegrationsInner,
  IntegrationsIconbox,
  IntegrationsContent,
  ButtonWrap,
} from "./style";

export default function WebsiteLocIntegrations({ data }) {
  return (
    <IntegrationsWrapper id={data.section}>
      <IntegrationsInner>
        <Container>
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <IntegrationsContent>
                <SectionTitle
                  subtitle={data.section_title.subtitle}
                  title={data.section_title.title}
                  description={data.section_title.description}
                />
                <ButtonWrap>
                  {data?.buttons?.map(({ path,index, content, ...rest }) => (
                    <Button key={path} path={path} {...rest}>
                      {content}
                    </Button>
                  ))}
                </ButtonWrap>
              </IntegrationsContent>
            </Col>
            <Col className="text-center">
              <IntegrationsIconbox>
                <Image src={data.images[0].src} alt="hessi" />
              </IntegrationsIconbox>
            </Col>
          </Row>
        </Container>
      </IntegrationsInner>
    </IntegrationsWrapper>
  );
}
WebsiteLocIntegrations.propTypes = {
  textStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  descStyle: PropTypes.object,
};

WebsiteLocIntegrations.defaultProps = {
  headingStyle: {
    as: "h4",
    color: "#fff",
    fontSize: "22px",
    fontweight: 700,
  },
  descStyle: {
    color: "#fff",
    mt: "50px",
  },
};
