import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import Slider3D from "@containers/global/slider-3d";
import WebsiteLocIntegrations from "@containers/website-localization/integrations";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import CaseStudy from "@containers/global/case-study";
import FaqArea from "@containers/faq/layout-03";
import BoxSectionThree from "@components/BoxSection/layout-two/layout-1";
import BoxSectionTwo from "@components/BoxSection/layout-one/layout-2";
import CtaArea from "@containers/cta/layout-02";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import StickyMenu from "@containers/sticky-menu";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";

const Footer = lazy(() => import("@layout/footer/layout-01"))
const WebsiteLocalizationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Website Localization Services | Localization & Translation"
        description="Premium Website Translation & Localization Services – We not only translate your site, but also optimize your localized site with relevant search terms."
        />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content["stycky-menu-data"]} />
      <main className="site-wrapper-reveal">
        <PageHeader  data={content["website-localization-header-data"]} />
        <QuoteForm />
        {
          isMobile && showRestComponents ? <>
            <PartnerArea data={content["website-localization-logo"]} />
          </> : !isMobile && <>
            <PartnerArea data={content["website-localization-logo"]} />
          </>
        }
        <FeatureGrid data={content["website-localization-features"]} />
        {
          showRestComponents && <>
        <IntroArea layout={2} data={content["website-localization-intro"]} />
        <Slider3D data={content["website-localization-applications"]} />
        <BoxSectionThree
          layout={2}
          data={content["website-localization-technology"]}
        />
        <WebsiteLocIntegrations
          data={content["website-localization-integration"]}
        />
        <CtaArea data={content["cta-data"]} />
        <IntroArea
          layout={2}
          data={content["website-localization-multi-seo"]}
        />
        <IntroAreaReverse
          data={content["website-localization-custo-process"]}
        />
        <BoxSectionTwo data={content["web-localization-quality"]} />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["website-localization-faq-body"]} />
        <ContactArea Layout={2} />
        </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query websiteLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "website-localization" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

WebsiteLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default WebsiteLocalizationPage;
